require('./modernizr.js')
require('./polyfills.js')

// Body Scroll Lock
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
} from 'body-scroll-lock'

// Quicklinks
import quicklink from 'quicklink/dist/quicklink.mjs'

// Lottie
import lottie from 'lottie-web/build/player/lottie'

// Anime.js
import anime from 'animejs'
;(function(global) {
    var simplefocus = {
        // Initialize everything
        init() {
            this.mobileMenu()
            this.linkPrefetching()
            this.socialShare()
            if (document.getElementById('feat-manage')) this.lottieAnimations()
            if (document.querySelector('.home-hero')) this.homeAnimation()
        },

        // Mobile menu toggling
        mobileMenu() {
            let $trigger = document.querySelector('.js-toggle-menu')
            $trigger.addEventListener('click', () => {
                let $mobileNav = document.querySelector('.mobile-nav')
                document.body.scrollTop = document.documentElement.scrollTop = 0

                if ($mobileNav.classList.contains('is-active')) {
                    // $mobileNav.classList.remove('hidden')
                    $mobileNav.classList.remove('is-active')
                    enableBodyScroll(document.body)
                } else {
                    // $mobileNav.classList.add('hidden')
                    $mobileNav.classList.add('is-active')
                    disableBodyScroll(document.body)
                }
            })
        },

        linkPrefetching() {
            quicklink()
        },

        lottieAnimations() {
            lottie.loadAnimation({
                container: document.getElementById('feat-manage'), // the dom element that will contain the animation
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: './src/js/animations/manage.json', // the path to the animation json
            })

            lottie.loadAnimation({
                container: document.getElementById('feat-connect'), // the dom element that will contain the animation
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: './src/js/animations/connect.json', // the path to the animation json
            })

            lottie.loadAnimation({
                container: document.getElementById('feat-viz'), // the dom element that will contain the animation
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: './src/js/animations/visualize3.json', // the path to the animation json
            })

            lottie.loadAnimation({
                container: document.getElementById('feat-share'), // the dom element that will contain the animation
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: './src/js/animations/share.json', // the path to the animation json
            })
        },

        socialShare() {
            /**
             *  Social sharing popup window
             */
            let jsSocialShares = document.querySelectorAll(
                '.social-share .popup'
            )
            if (jsSocialShares) {
                ;[].forEach.call(jsSocialShares, function(anchor) {
                    anchor.addEventListener('click', function(e) {
                        let url = this.href,
                            width = 500,
                            height = 300,
                            left = screen.width / 2 - width / 2,
                            top = screen.height / 2 - height / 2

                        if (
                            /^(f|ht)tps?:\/\//i.test(url) ||
                            /^mailto/i.test(url)
                        ) {
                            e.preventDefault()
                            window.open(
                                url,
                                '',
                                'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=' +
                                    width +
                                    ',height=' +
                                    height +
                                    ',top=' +
                                    top +
                                    ',left=' +
                                    left
                            )
                        }
                    })
                })
            }
        },

        homeAnimation() {
            let barsEasing = 'cubicBezier(0.24, 1.37, 0.46, 1)'
            let dotsEasing = 'cubicBezier(0.17, 2.1, 0.46, 1)'

            let updateMarkerElements = () => {
                setMarkerArrowAngle()
                setMarkerCircleSize()
            }

            let setMarkerArrowAngle = () => {
                // Get marker circle and expense bar coords
                let markerCircle = document
                    .querySelector('.home-hero .marker-circle-wrapper')
                    .getBoundingClientRect()
                let markerCircleCoords = {
                    x: markerCircle.right - markerCircle.width * 0.15,
                    y: markerCircle.bottom,
                }
                let expenseBar = document
                    .querySelector('.home-hero .bars-may .bar-red')
                    .getBoundingClientRect()
                let expenseBarCoords = {
                    x:
                        expenseBar.right -
                        expenseBar.width * (window.innerWidth / 1200 + 0.75),
                    y: expenseBar.top,
                }

                // Get the angle between the points
                // https://gist.github.com/conorbuck/2606166
                let arrowAngle =
                    (Math.atan2(
                        expenseBarCoords.y - markerCircleCoords.y,
                        expenseBarCoords.x - markerCircleCoords.x
                    ) *
                        180) /
                    Math.PI
                anime.set('.home-hero .marker-arrow-wrapper', {
                    rotate: `${arrowAngle}deg`,
                })

                // document.body.innerHTML += `<div class="absolute w-2 h-2 bg-blue-200" style="left: ${markerCircleCoords.x}px; top: ${markerCircleCoords.y}px;"></div>`
                // document.body.innerHTML += `<div class="absolute w-2 h-2 bg-blue-200" style="left: ${expenseBarCoords.x}px; top: ${expenseBarCoords.y}px;"></div>`
            }

            let setMarkerCircleSize = () => {
                let {
                    width: markerCircleWrapperWidth,
                } = document
                    .querySelector('.marker-circle-wrapper')
                    .getBoundingClientRect()
                let $markerCircle = document.querySelector('.marker-circle')

                $markerCircle.style.width = markerCircleWrapperWidth + 40
            }

            // Append marker circle and arrow to word
            let markerCircleSVG = `
                <svg class="marker-circle" width="386" height="87" viewBox="0 0 386 87" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><g transform="translate(3.000000, 3.000000)" stroke="#3BA0F2" stroke-width="7"><path class="circle" d="M144.55,21.15 C88,13.77 -1.91,32.48 0.55,52.15 C3.73,77.57 130.55,83.15 212.55,79.15 C272.67,76.22 381.75,68.95 378.55,40.15 C373.63,-4.11 114.89,-13.33 16.55,22.73" id="Path"></path></g></g></svg>
            `
            let markerArrowSVG = `
                <div class="marker-arrow-wrapper">
                    <svg class="marker-arrow" width="150" height="63" viewBox="0 0 150 63" xmlns="http://www.w3.org/2000/svg"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><g transform="translate(3.000000, -46.000000)" stroke="#3BA0F2" stroke-width="6"><g id="Group" transform="translate(72.502285, 68.487514) rotate(-53.000000) translate(-72.502285, -68.487514) translate(31.002285, 9.987514)"><path class="line" d="M0.233522499,0.0166880603 C7.4435225,27.2866881 36.2,79.46 68.09,104.86"></path><path class="point" d="M28.2,102.73 C45.5,109.86 83,116.5 83,116.5 C83,116.5 65.23,74.07 65.23,59"></path></g></g></g></svg>
                </div>
            `
            let circleTarget = document.querySelector('.marker-circle-wrapper')
            circleTarget.innerHTML = `${markerCircleSVG}${markerArrowSVG}${circleTarget.innerHTML}`

            // Update marker circle size
            setMarkerCircleSize()

            // Show things
            anime.set(['.home-hero-gradient, .home-hero'], {
                visibility: 'visible',
            })

            // Hero Text
            anime({
                targets: [
                    '.home-hero__title',
                    '.home-hero__content',
                    '.home-hero__pricing',
                ],
                opacity: [0, 1],
                easing: 'linear',
                duration: 750,
                delay: anime.stagger(100),
                // autoplay: false,
            })

            // Hero Button
            anime({
                targets: '.home-hero__button',
                opacity: [0, 1],
                scale: [0.75, 1],
                easing: 'linear',
                duration: 750,
                // autoplay: false,
            })

            // Dots
            let dots = document.querySelectorAll('.bar-graph .dot')
            anime({
                targets: [].slice.call(dots, 0).reverse(),
                // Scale
                // scale: [0, 1],
                r: [0, 12.5],
                easing: dotsEasing,
                duration: 500,
                delay: anime.stagger(100),
                // autoplay: false,
            })

            // Green Bars
            let greenBars = document.querySelectorAll('.bar-graph .bar-green')
            anime({
                targets: [].slice.call(greenBars, 0).reverse(),
                scaleY: [0, 1],
                easing: barsEasing,
                duration: 500,
                delay: anime.stagger(100),
                // autoplay: false,
            })

            // Red Bars
            let redBars = document.querySelectorAll('.bar-graph .bar-red')
            anime({
                targets: [].slice.call(redBars, 0).reverse(),
                scaleY: [0, 1],
                easing: barsEasing,
                duration: 500,
                delay: anime.stagger(100, { start: 100 }),
                // autoplay: false,
                complete: (anim) => {
                    setMarkerArrowAngle()
                },
            })

            // Months
            anime({
                targets: ['.bar-graph .months', '.bar-graph .lines'],
                opacity: [0, 1],
                easing: 'linear',
                duration: 500,
                delay: 150,
                // autoplay: false,
            })

            // Start the animations
            // fadeHeroContent.play()
            // animateHeroButton.play()
            // animateDots.play()
            // animateGreenBars.play()
            // animateRedBars.play()
            // animateMonths.play()

            // Marker Timeline
            anime
                .timeline()
                // Marker Circle
                .add({
                    targets: '.home-hero .marker-circle .circle',
                    display: ['none', 'block'],
                    strokeDashoffset: [anime.setDashoffset, 0],
                    easing: 'easeInOutCubic',
                    duration: 750,
                    delay: 750,
                })
                // Marker Arrow Line
                .add({
                    targets: '.home-hero .marker-arrow .line',
                    display: ['none', 'block'],
                    strokeDashoffset: [anime.setDashoffset, 0],
                    easing: 'easeInOutCirc',
                    duration: 500,
                })
                // Marker Arrow Point
                .add({
                    targets: '.home-hero .marker-arrow .point',
                    display: ['none', 'block'],
                    strokeDashoffset: [anime.setDashoffset, 0],
                    easing: 'easeInOutCirc',
                    duration: 750,
                })

            // Listen for resize events
            let throttled
            window.addEventListener(
                'resize',
                (event) => {
                    if (throttled) window.cancelAnimationFrame(throttled)
                    throttled = window.requestAnimationFrame(
                        updateMarkerElements
                    )
                },
                false
            )
        },
    }

    // Add window object
    global.simplefocus = simplefocus

    // Run it
    simplefocus.init()
})(window)
